@import "@/services/scss/variables.scss";
@import "@/services/scss/mixin.scss";

.error_main {
    min-height: 100vh;
    text-align: center;

    h1 {
        word-break: break-all;
    }
}

.button_list {
    @include itemsCenter;
    justify-content: center;

    a,
    button {
        background-color: transparent;
        border: none;

        background-color: $primary;
        padding: 10px;
        margin: 0;
        @include borderRadius;
        color: $white;
        font-size: 12px;
        font-weight: 600;
        min-width: 40%;
    }

    a {
        background-color: $orange;
    }
}

.not_found_wrap {
    @include itemsCenter;
    justify-content: center;
    flex-direction: column;
    min-height: 90vh;
    text-align: center;

    h1 {
        font-size: 24px;
        font-weight: bolder;
    }
    p {
        font-size: 18px;
        font-weight: 400;
    }

    a {
        background-color: $primary;
        padding: 10px;
        color: $white;
        @include borderRadius;
    }
    .img_wrap {
        img {
            max-width: 300px;
        }
    }
}
