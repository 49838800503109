@import "@/services/scss/variables.scss";
@import "@/services/scss/mixin.scss";

.img_wrap {
    position: relative;
    width: 100%;
    @include flexCenter;
    @include borderRadius;
    overflow: hidden;

    img {
        position: relative !important;
        object-fit: contain;
        width: 100%;
        height: auto;
        @include borderRadius;
    }
}
